import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

const Tables = () => (
  <Container fluid className="main-content-container px-1">
    {/* Default Light Table */}
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h5 className="m-0">Today Follow Up - 10</h5>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                    MRD Number
                  </th>
                  <th scope="col" className="border-0">
                    Phone
                  </th>
                  <th scope="col" className="border-0">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ali</td>
                  <td>2154</td>

                  <td>956864458</td>
                  <td>
                    <Button
                      layout="link"
                      size="small"
                      aria-label="Edit"
                      theme="dark"
                      outline
                    >
                      View
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Clark</td>
                  <td>15545</td>

                  <td>894578545</td>
                  <td>
                    <Button
                      layout="link"
                      size="small"
                      aria-label="Edit"
                      theme="dark"
                      outline
                    >
                      View
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Jerry</td>
                  <td>54565</td>

                  <td>998744546</td>
                  <td>
                    <Button
                      layout="link"
                      size="small"
                      aria-label="Edit"
                      theme="dark"
                      outline
                    >
                      View
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Colt</td>
                  <td>44555</td>

                  <td>899595645</td>
                  <td>
                    <Button
                      layout="Outline"
                      size="small"
                      aria-label="Edit"
                      theme="dark"
                      outline
                    >
                      View
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Upcoming Follow Up List</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                  MRD Number
                  </th>

                  <th scope="col" className="border-0">
                    Phone
                  </th>
                  <th scope="col" className="border-0">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ali</td>
                  <td>15425</td>

                  <td>956864458</td>
                  <td>
                    
                    <Button theme="dark" outline className="mr-1">
                      View
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Clark</td>
                  <td>12451</td>

                  <td>894578545</td>
                  <td>
                    <Button
                      layout="link"
                      size="small"
                      aria-label="Edit"
                      theme="dark"
                      outline
                    >
                      View
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Jerry</td>
                  <td>32541</td>

                  <td>998744546</td>
                  <td>
                    <Button
                      layout="link"
                      size="small"
                      aria-label="Edit"
                      theme="dark"
                      outline
                    >
                      View
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Colt</td>
                  <td>24512</td>

                  <td>899595645</td>
                  <td>
                    <Button
                      layout="Outline"
                      size="small"
                      aria-label="Edit"
                      theme="dark"
                      outline
                    >
                      View
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Tables;
