import React, { Component } from "react";
import {Link} from "react-router-dom"

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
// import PageTitle from "../components/common/PageTitle";

const axios = require("axios").default;
const URL = require("../ApiUrl");
class DashboardTables extends Component {
  state = {
    page: 1,
    limit: 20,
    tomorrowPatientData: [],
    todayPatientData : [],
    pendingPatientData : [],
    todayTotalCount : 0,
    tomorrowTotalCount : 0,
    pendingTotalCount : 0
  };

  getData = async (flag) => {
    // console.log(l, p);
    let page = this.state.page;
    let limit = this.state.limit;

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    
    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/patient/list?page=1&limit=20&followUpFlag=${flag}`,

      headers: { Authorization: `Bearer ${token}` }
    });

    //  console.log(response);
    if (response.data.statusCode === 200 && flag === 'today') {
      let todayPatientData = response.data.data.items;
      let todayTotalCount = response.data.data.totalCount;

      this.setState({
        todayPatientData,
        todayTotalCount
      });
    }else if(response.data.statusCode === 200 && flag === 'tomorrow'){
      let tomorrowPatientData = response.data.data.items;
      let tomorrowTotalCount = response.data.data.totalCount;
      console.log("tomorrowPatientData")
      console.log(tomorrowPatientData)
      console.log("tomorrowPatientData")
      this.setState({
        tomorrowPatientData,
        tomorrowTotalCount
      });
    }else if(response.data.statusCode === 200 && flag === 'pending'){
      let pendingPatientData = response.data.data.items;
      let pendingTotalCount = response.data.data.totalCount;
      console.log("pendingPatientData")
      console.log(pendingPatientData)
      console.log("pendingPatientData")
      this.setState({
        pendingPatientData,
        pendingTotalCount
      });
    }

    console.log(this.state.patientData);
  };

  componentDidMount() {
    this.getData('pending');
    this.getData('today');
    this.getData('tomorrow');
  }
  render() {
    let todayPatientData = [];
    let tomorrowPatientData = [];
    let pendingPatientData = [];
    let todayTotalCount = 0;
    let tomorrowTotalCount = 0;
    let pendingTotalCount = 0;
    todayPatientData = this.state.todayPatientData;
    tomorrowPatientData = this.state.tomorrowPatientData;
    pendingPatientData = this.state.pendingPatientData;
    todayTotalCount = this.state.todayTotalCount;
    tomorrowTotalCount = this.state.tomorrowTotalCount;
    pendingTotalCount = this.state.pendingTotalCount;
    return (
        <Container fluid className="main-content-container px-1">
    {/* Default Light Table */}
    
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h5 className="m-0">Today Follow Up - {todayTotalCount}</h5>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                    MRD Number
                  </th>
                  <th scope="col" className="border-0">
                    Phone
                  </th>
                  <th scope="col" className="border-0">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
              
                {todayPatientData.map((items, key) => {
                 if (items.length > 0) {
                   return (
                     <tr>
                       <td>No items</td>
                     </tr>
                   );
                 } else {
                   return (
                     <tr>
                       <td>{key + 1}</td>
                       <td>{items.name}</td>
                       <td>{items.MRDNo}</td>
                       <td>{items.mobile}</td>
                       <td>
                       <Link to={`followupdata/${items._id}`}>
                            <Button  theme="dark" outline>
                             <i class="material-icons">visibility</i> Follow up
                           </Button>
                         </Link>
                       </td>
                     </tr>
                   );
                 }
               })}
             </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Tomorrow Follow Up - {tomorrowTotalCount}</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                  MRD Number
                  </th>

                  <th scope="col" className="border-0">
                    Phone
                  </th>
                  <th scope="col" className="border-0">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {tomorrowPatientData.map((items, key) => {
                 if (items.length > 0) {
                   return (
                     <tr>
                       <td>No items</td>
                     </tr>
                   );
                 } else {
                   return (
                     <tr>
                       <td>{key + 1}</td>
                       <td>{items.name}</td>
                       <td>{items.MRDNo}</td>
                       <td>{items.mobile}</td>
                       <td>
                       <Link to={`followupdata/${items._id}`}>
                            <Button  theme="dark" outline>
                             <i class="material-icons">visibility</i> Follow up
                           </Button>
                         </Link>
                       </td>
                     </tr>
                   );
                 }
               })}
             </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h5 className="m-0">Pending Follow Up - {pendingTotalCount}</h5>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                    MRD Number
                  </th>
                  <th scope="col" className="border-0">
                    Phone
                  </th>
                  <th scope="col" className="border-0">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
              
                {pendingPatientData.map((items, key) => {
                 if (items.length > 0) {
                   return (
                     <tr>
                       <td>No items</td>
                     </tr>
                   );
                 } else {
                   return (
                     <tr>
                       <td>{key + 1}</td>
                       <td>{items.name}</td>
                       <td>{items.MRDNo}</td>
                       <td>{items.mobile}</td>
                       <td>
                       <Link to={`followupdata/${items._id}`}>
                            <Button  theme="dark" outline>
                             <i class="material-icons">visibility</i> Follow up
                           </Button>
                         </Link>
                       </td>
                     </tr>
                   );
                 }
               })}
             </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
    )
  }
}

export default DashboardTables;