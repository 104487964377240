import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormTextarea,
  Button,
  Container,
  FormSelect,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  Alert,
  ModalHeader
} from "shards-react";
import Cookie from "js-cookie";
import { FormText } from "reactstrap";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export class AddPatient extends Component {
  state = {
    isLoaded: false,
    isRedirect: false,
    updated: false,
    error: false,
    errorCode: "",
    name: "",
    MRDNo: "",
    gender: "",
    mobile: "",
    whatsappNo: "",
    age: "",
    followUpDateTs: "",
    note: "",
    place: "",
    timestamp: "",
    id: {},
    patientData: {},
    open: false,
    msg: "",
    errorflag: false,
    errorMsg: "",
    errordata: ""
  };

  PostPatient = async e => {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let timestamp = Date.parse(new Date(this.state.followUpDateTs).toString());
    console.log(timestamp, "🎈🎈🎈🎈🎈🎈");
    let patientData = {
      name: this.state.name,
      MRDNo: this.state.MRDNo,
      gender: this.state.gender,
      mobile: this.state.mobile,
      whatsappNo: this.state.whatsappNo,
      age: this.state.age,
      followUpDateTs: timestamp,
      note: this.state.note,
      place: this.state.place
    };

    patientData.id = this.props.match.params.id;

    console.log(patientData);
    if (this.state.note === "") {
      patientData.note = "Follow up done";
    }
    let response = await axios
      .post(`${URL.BASE_URL}/patient/create`, patientData, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        updated: true,
        isRedirect: true,
        errorCode: response.data.msg
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
        updated: false
      });
    }, 2000);

    console.log(response);
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    // console.log(this.state);
  };
  getMRDCheck = async e => {
    const MRDNo = e.target.value;

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/patient/mrdno/check?MRDNo=${MRDNo}`,

      headers: { Authorization: `Bearer ${token}` }
    });

    console.log(response);
    if (response.data.statusCode === 200) {
      this.setState({
        errorflag: false
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        errorflag: true,
        errorMsg: response.data.msg,
        errordata: response.data.data
      });
    } else {
      this.setState({
        status: false
      });
    }

    console.log(this.state.errordata);
  };
  componentDidMount() {
    this.getMRDCheck();
  }

  getData = async () => {
    // console.log(l, p);

    if (this.props.match.params.id) {
      let id = this.props.match.params.id;
      console.log(id, "_+:::::::::::::::::::::::::::::::;");
      const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

      let response = await axios({
        method: "get",
        url: `${URL.BASE_URL}/patient/${id}/detail`,

        headers: { Authorization: `Bearer ${token}` }
      });

      console.log(response);
      if (response.data.statusCode === 200) {
        this.setState({
          patientData: response.data.data,
          isLoaded: true
        });
      }

      console.log(this.state.patientData);
    }
  };
  componentDidMount = () => {
    this.getData();
  };
  maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  onInputChange(e) {
    this.setState({
      inputValue: e.target.value
    });
  }
  UpdatePatient = async e => {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let updatepatientData = {
      name: this.state.name,
      MRDNo: this.state.MRDNo,
      gender: this.state.gender,
      mobile: this.state.mobile,
      whatsappNo: this.state.whatsappNo,
      age: this.state.age,

      note: this.state.note,
      place: this.state.place
    };

    let id = this.props.match.params.id;

    console.log(updatepatientData);

    let response = await axios
      .patch(`${URL.BASE_URL}/patient/${id}/update`, updatepatientData, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        updated: true,
        isRedirect: true,
        errorCode: response.data.msg
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
        updated: false
      });
    }, 2000);

    console.log(response);
  };

  BottomButton = () => {
    if (this.props.match.params.id) {
      // console.log(this.props.match.params.id, "+++++++++++++++++++");
      return (
        <>
          <Row className="d-flex justify-content-center">
            <Col lg="0" className="mt-8 mr-2">
              <Button theme="accent" size="lg" onClick={this.UpdatePatient}>
                <i class="fas fa-edit"></i> Update Details
              </Button>
            </Col>
            <Col lg="0" className="mt-8 ml-2">
              <this.Delete />
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <Row className="d-flex justify-content-center">
          <Col lg="0" className="mt-8">
            <Button onClick={this.PostPatient} theme="accent" size="lg">
              <i class="fas fa-user-plus"></i> Add Patient
            </Button>
          </Col>
        </Row>
      );
    }
  };
  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  };
  Delete = () => {
    const { open } = this.state;
    return (
      <div>
        <Button onClick={this.toggle} size="lg">
          <i class="fas fa-trash-alt"></i> Delete
        </Button>
        <Modal open={open} toggle={this.toggle}>
          <ModalHeader>
            <i class="fas fa-exclamation-triangle"></i> Delete
          </ModalHeader>
          <ModalBody>
            <p className="text-center">
              Are you sure you want to delete this item ?
            </p>
            <p className="text-center">This action cannot be undone.</p>
          </ModalBody>
          <ModalFooter>
            <Row className="d-flex justify-content-center">
              <Col lg="0" className="mt-8 mr-2">
                <Button theme="danger" onClick={this.DeletePatient}>
                  Delete
                </Button>
              </Col>
              <Col lg="0" className="mt-8">
                <Button theme="accent" onClick={this.toggle}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  DeletePatient = async e => {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let id = this.props.match.params.id;

    let response = await axios
      .delete(`${URL.BASE_URL}/patient/${id}/delete`, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
        errorCode: response.data.msg
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false
      });
    }, 2000);

    console.log(response);
  };

  AddDetails = () => {
    const errorflag = this.state.errorflag;
    let errordata;
    return (
      <>
        <Col>
          <Form>
            <Row form>
              {/* First Name */}
              <Col md="6" className="form-group">
                <label htmlFor="feLastName">Name</label>
                <FormInput
                  id="feLastName"
                  placeholder="Name"
                  name="name"
                  defaultValue={this.state.patientData.name}
                  onChange={this.handleChange}
                />
              </Col>
              <Col md="6" className="form-group">
                <label htmlFor="feFirstName">MRD Number</label>

                {errorflag === true ? (
                  <FormInput
                    invalid
                    type="number"
                    name="MRDNo"
                    placeholder="Number"
                    maxLength="7"
                    onInput={this.maxLengthCheck}
                    onBlur={this.getMRDCheck}
                    onChange={this.handleChange}
                  />
                ) : (
                  <FormInput
                    valid
                    type="number"
                    name="MRDNo"
                    maxLength="7"
                    onInput={this.maxLengthCheck}
                    placeholder="Number"
                    defaultValue={this.state.patientData.MRDNo}
                    onBlur={this.getMRDCheck}
                    onChange={this.handleChange}
                  />
                )}
                {errorflag === true ? (
                  <FormText>{this.state.errorMsg}</FormText>
                ) : (
                  <FormText></FormText>
                )}
                {/* <FormInput
                  id="feFirstName"
                  type="number"
                  name="MRDNo"
                  placeholder="Number"
                  defaultValue={this.state.patientData.MRDNo}
                  onChange={this.getMRDCheck}
                /> */}
              </Col>
              {/* Last Name */}
            </Row>
            <Row form>
              {/* Email */}
              <Col md="6" className="form-group">
                <label htmlFor="feEmail">Gender</label>
                <FormSelect name="gender" onChange={this.handleChange}>
                  <option value="none">
                    {this.state.patientData
                      ? this.state.patientData.gender
                      : "Select"}
                  </option>
                  <option>Male</option>
                  <option>Female</option>
                </FormSelect>
              </Col>
              <Col md="6" className="form-group">
                <label htmlFor="fePassword">Age</label>
                <FormInput
                  type="number"
                  name="age"
                  maxLength="2"
                  onInput={this.maxLengthCheck}
                  placeholder="Number"
                  onChange={this.handleChange}
                  defaultValue={this.state.patientData.age}
                />
              </Col>
              {/* Password */}
            </Row>
            <Row form>
              {/* Email */}
              <Col md="4" className="form-group">
                <label htmlFor="fePassword">Phone Number</label>
                <FormInput
                  type="number"
                  name="mobile"
                  maxLength="10"
                  onInput={this.maxLengthCheck}
                  placeholder="Number"
                  onChange={this.handleChange}
                  defaultValue={this.state.patientData.mobile}
                />
              </Col>
              <Col md="4" className="form-group">
                <label htmlFor="fePassword">Whatsapp Number</label>
                <FormInput
                  type="number"
                  name="whatsappNo"
                  maxLength="10"
                  onInput={this.maxLengthCheck}
                  placeholder="Number"
                  onChange={this.handleChange}
                  defaultValue={this.state.patientData.whatsappNo}
                />
              </Col>
              {/* Password */}
              <Col md="4" className="form-group">
                <label htmlFor="feLastName">Place</label>
                <FormInput
                  id="feLastName"
                  name="place"
                  placeholder="Name"
                  onChange={this.handleChange}
                  defaultValue={this.state.patientData.place}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </>
    );
  };
  FollowupDetails = () => {
    if (!this.props.match.params.id) {
      return (
        <>
          <Col>
            <Form>
              <h4>Follow up details</h4>
              <hr />
              <Row form>
                {/* Description */}
                <Col md="12" className="form-group">
                  <label htmlFor="feDescription">Note</label>
                  <FormTextarea
                    id="feDescription"
                    rows="5"
                    name="note"
                    onChange={this.handleChange}
                    defaultValue="Follow Up Done"
                  />
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="fePassword">Next Follow Up Date</label>
                  <FormInput
                    type="date"
                    name="followUpDateTs"
                    onChange={this.handleChange}
                    defaultValue={this.state.patientData.followUpDateTs}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </>
      );
    } else {
      return <></>;
    }
  };
  Alerts = () => {
    if (this.state.updated) {
      return (
        <>
          <Alert theme="success">
            <a className="alert-link px-5 py-4 "> {this.state.errorCode}</a>
          </Alert>
        </>
      );
    } else if (this.state.error) {
      return (
        <>
          <Alert theme="warning">
            <a className="alert-link px-5 py-4"> {this.state.errorCode}</a>
          </Alert>
        </>
      );
    } else {
      return <></>;
    }
  };
  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/patient-list" />;
    }
    return (
      <Container fluid className="main-content-container px-5 py-4">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Add patient</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <this.AddDetails />
              </Row>
              <this.FollowupDetails />
              <this.BottomButton />
            </ListGroupItem>
          </ListGroup>
        </Card>
        <this.Alerts />
      </Container>
    );
  }
}

export default AddPatient;
