import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormTextarea,
  Button,
  Container,
  FormSelect,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  Alert,
  ModalHeader
} from "shards-react";
import Cookie from "js-cookie";

const axios = require("axios").default;
const URL = require("../ApiUrl");

export class AddInvigilator extends Component {
  state = {
    isLoaded: false,
    isRedirect: false,
    updated: false,
    error: false,
    errorCode: "",
    name: "",
    phone: "",
    email: "",
    password:"",
    id: {},
    userData: {},
    open: false
  };

  PostPatient = async e => {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let userData = {
      name: this.state.name,
      password: this.state.password,
      email: this.state.email,
      phone: this.state.phone,
      
    };
    userData.id = this.props.match.params.id;
    console.log(userData);
    let response = await axios
      .post(`${URL.BASE_URL}/user/create`, userData, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        updated: true,
        isRedirect: true,
        errorCode: response.data.msg
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
        updated: false
      });
    }, 2000);

    console.log(response);
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    // console.log(this.state);
  };

  getData = async () => {
    // console.log(l, p);

    if (this.props.match.params.id) {
      let id = this.props.match.params.id;
      console.log(id, "_+:::::::::::::::::::::::::::::::;");
      const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

      let response = await axios({
        method: "get",
        url: `${URL.BASE_URL}/user/${id}/detail`,

        headers: { Authorization: `Bearer ${token}` }
      });

      console.log(response);
      if (response.data.statusCode === 200) {
        this.setState({
          userData: response.data.data,
          isLoaded: true
        });
      }

      console.log(this.state.userData);
    }
  };
  componentDidMount = () => {
    this.getData();
  };
  maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  onInputChange(e) {
    this.setState({
      inputValue: e.target.value
    });
  }
  UpdatePatient = async e => {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let updateuserData = {
        name: this.state.name,
        password: this.state.password,
        email: this.state.email,
        phone: this.state.phone,
    };

    let id = this.props.match.params.id;

    console.log(updateuserData);

    let response = await axios
      .patch(`${URL.BASE_URL}/user/${id}/update`, updateuserData, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        updated: true,
        isRedirect: true,
        errorCode: response.data.msg
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
        updated: false
      });
    }, 2000);

    console.log(response);
  };

  BottomButton = () => {
    if (this.props.match.params.id) {
      // console.log(this.props.match.params.id, "+++++++++++++++++++");
      return (
        <>
          <Row className="d-flex justify-content-center">
            <Col lg="0" className="mt-8 mr-2">
              <Button theme="accent" size="md" onClick={this.UpdatePatient}>
                <i class="fas fa-edit"></i> Update Details
              </Button>
            </Col>
            <Col lg="0" className="mt-8 ml-2">
              <this.Delete />
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <Row className="d-flex justify-content-center">
          <Col lg="0" className="mt-8">
            <Button onClick={this.PostPatient} theme="accent" size="md">
              <i class="fas fa-user-plus"></i> Add User
            </Button>
          </Col>
        </Row>
      );
    }
  };
  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  };
  Delete = () => {
    const { open } = this.state;
    return (
      <div>
        <Button onClick={this.toggle} size="md">
          <i class="fas fa-trash-alt"></i> Delete
        </Button>
        <Modal open={open} toggle={this.toggle}>
          <ModalHeader>
            <i class="fas fa-exclamation-triangle"></i> Delete
          </ModalHeader>
          <ModalBody>
            <p className="text-center">
              Are you sure you want to delete this item ?
            </p>
            <p className="text-center">This action cannot be undone.</p>
          </ModalBody>
          <ModalFooter>
            <Row className="d-flex justify-content-center">
              <Col lg="0" className="mt-8 mr-2">
                <Button theme="danger" onClick={this.DeletePatient}>
                  Delete
                </Button>
              </Col>
              <Col lg="0" className="mt-8">
                <Button theme="accent" onClick={this.toggle}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  };

  DeletePatient = async e => {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let id = this.props.match.params.id;

    let response = await axios
      .delete(`${URL.BASE_URL}/user/${id}/delete`, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
        errorCode: response.data.msg
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false
      });
    }, 2000);

    console.log(response);
  };

  AddDetails = () => {
    return (
      <>
        <Col>
          <Form>
            <Row form>
              {/* First Name */}
              <Col md="6" className="form-group">
                <label htmlFor="feLastName">User Name</label>
                <FormInput
                  id="feLastName"
                  placeholder="Name"
                  name="name"
                  defaultValue={this.state.userData.name}
                  onChange={this.handleChange}
                />
              </Col>
              <Col md="6" className="form-group">
                <label htmlFor="feFirstName">Email</label>
                <FormInput
                  id="feFirstName"
                  type="email"
                  name="email"
                  placeholder="abcd@abcd.com"
                  defaultValue={this.state.userData.email}
                  onChange={this.handleChange}
                />
              </Col>
              {/* Last Name */}
            </Row>

            <Row form>
             
              <Col md="6" className="form-group">
                <label htmlFor="fePassword">Phone Number</label>
                <FormInput
                  type="number"
                  name="phone"
                  maxLength="10"
                  onInput={this.maxLengthCheck}
                  placeholder="Number"
                  onChange={this.handleChange}
                  defaultValue={this.state.userData.phone}
                />
              </Col>
              <Col md="6" className="form-group">
                <label htmlFor="fePassword">Password</label>
                <FormInput
                  type="password"
                  name="password"
                  onChange={this.handleChange}
                  
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </>
    );
  };

  Alerts = () => {
    if (this.state.updated) {
      return (
        <>
          <Alert theme="success">
            <a className="alert-link px-5 py-4 "> {this.state.errorCode}</a>
          </Alert>
        </>
      );
    } else if (this.state.error) {
      return (
        <>
          <Alert theme="warning">
            <a className="alert-link px-5 py-4"> {this.state.errorCode}</a>
          </Alert>
        </>
      );
    } else {
      return <></>;
    }
  };
  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/invigilator-list" />;
    }
    return (
      <Container fluid className="main-content-container px-5 py-4">
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Add Invigilator</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <this.AddDetails />
              </Row>

              <this.BottomButton />
            </ListGroupItem>
          </ListGroup>
        </Card>
        <this.Alerts />
      </Container>
    );
  }
}

export default AddInvigilator;
