import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormTextarea,
  Button,
  Container,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  Alert,
  ModalFooter,
  DatePicker,
  FormCheckbox
} from "shards-react";
import Cookie from "js-cookie";
import PageTitle from "../components/common/PageTitle";

const axios = require("axios").default;
const URL = require("../ApiUrl");

class Followup extends Component {
  state = {
    page: 1,
    limit: 20,
    originalData: [],
    patientData: {},
    followUpDateTs: "",
    note: "",
    place: "",
    timestamp: "",
    id: {},
    patientData: {},
    open: false,
    open1: false,
    deletedId: "",
    isClosed: false
  };
  PostPatient = async e => {
    e.preventDefault();

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let timestamp = Date.parse(new Date(this.state.followUpDateTs).toString());
    console.log(timestamp, "🎈🎈🎈🎈🎈🎈");
    let patientData = {
      followUpDateTs: timestamp,
      note: this.state.note,
      isClosed: this.state.isClosed
    };

    console.log(patientData);
    let id = this.props.match.params.id;
    if (this.state.note === "") {
      patientData.note = "Follow up done";
    }
    let response = await axios
      .post(`${URL.BASE_URL}/patient/${id}/note/add`, patientData, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        updated: true,
        isRedirect: true,
        errorCode: response.data.msg
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
        updated: false
      });
    }, 2000);

    console.log(response);
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    console.log(this.state);
  };
  getData = async () => {
    // console.log(l, p);

    if (this.props.match.params.id) {
      let id = this.props.match.params.id;
      console.log(id, "_+:::::::::::::::::::::::::::::::;");
      const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

      let response = await axios({
        method: "get",
        url: `${URL.BASE_URL}/patient/${id}/note/list`,

        headers: { Authorization: `Bearer ${token}` }
      });

      console.log(response);
      if (response.data.statusCode === 200) {
        this.setState({
          patientData: response.data.data,
          isLoaded: true
        });
      }

      console.log(this.state.patientData);
    }
  };
  componentDidMount = () => {
    this.getData();
  };

  DeleteNote = async e => {
    let id = this.state.deletedId;
    console.log(id, "________________________________");
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let response = await axios
      .delete(`${URL.BASE_URL}/patient/note/${id}/delete`, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        errorCode: response.data.msg
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false
      });
    }, 2000);
    this.getData();
    this.toggle();
    console.log(response);
  };
  toggle = id => {
    this.setState({
      open: !this.state.open,
      deletedId: id
    });
  };
  Delete = () => {
    const { open } = this.state;
    return (
      <div>
        <Modal open={open} toggle={this.toggle}>
          <ModalHeader>
            <i class="fas fa-exclamation-triangle"></i> Delete
          </ModalHeader>
          <ModalBody>
            <p className="text-center">
              Are you sure you want to delete this item ?
            </p>
            <p className="text-center">This action cannot be undone.</p>
          </ModalBody>
          <ModalFooter>
            <Row className="d-flex justify-content-center">
              <Col lg="0" className="mt-8 mr-2">
                <Button theme="danger" onClick={this.DeleteNote}>
                  Delete
                </Button>
              </Col>
              <Col lg="0" className="mt-8">
                <Button theme="accent" onClick={this.toggle}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  PatchNote = async e => {
    e.preventDefault();
    let id = this.state.deletedId;
    console.log(id, "________________________________");
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let timestamp = Date.parse(new Date(this.state.followUpDateTs).toString());
    console.log(timestamp, "🎈🎈🎈🎈🎈🎈");
    let patientData = {
      followUpDateTs: timestamp,
      note: this.state.note
    };
    console.log(patientData);

    let response = await axios
      .patch(`${URL.BASE_URL}/patient/note/${id}/update`, patientData, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        updated: true,
        errorCode: response.data.msg
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
        updated: false
      });
    }, 2000);
    this.getData();
    this.toggleEdit();
    console.log(response);
  };
  toggleEdit = id => {
    this.setState({
      open1: !this.state.open1,
      deletedId: id
    });
  };
  Edit = () => {
    const { open1 } = this.state;
    return (
      <div>
        <Modal open={open1} toggleEdit={this.toggleEdit}>
          <ModalHeader>
            <i class="fas fa-exclamation-triangle"></i> Edit Note
          </ModalHeader>
          <ModalBody>
            <Col md="12" className="form-group">
              <label htmlFor="feDescription">Note</label>
              <FormTextarea rows="5" name="note" onChange={this.handleChange} />
            </Col>
            <Col md="12" className="form-group">
              <label htmlFor="fePassword">Next Follow Up Date</label>
              <FormInput
                type="date"
                name="followUpDateTs"
                onChange={this.handleChange}
              />
            </Col>
          </ModalBody>
          <ModalFooter>
            <Row className="d-flex justify-content-center">
              <Col lg="0" className="mt-8 mr-2">
                <Button theme="danger" onClick={this.PatchNote}>
                  Update Note
                </Button>
              </Col>
              <Col lg="0" className="mt-8">
                <Button theme="accent" onClick={this.toggleEdit}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  List = () => {
    let patientList = [];

    if (this.props.match.params.id) {
      if (this.state.patientData.items) {
        patientList = this.state.patientData.items;
      }
      return (
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0"></h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Date
                    </th>
                    <th scope="col" className="border-0">
                      Follow Up Note
                    </th>
                    <th scope="col" className="border-0">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patientList.map((items, key) => {
                    {
                      return (
                        <tr>
                          <td>{key + 1}</td>
                          <td>
                            {new Date(
                              items.followUpDateTs
                            ).toLocaleDateString()}
                          </td>
                          <td>{items.note}</td>
                          <td>
                            <Button
                              onClick={() => this.toggleEdit(items._id)}
                              className="mt-8 ml-2"
                            >
                              <i class="material-icons">edit</i>
                            </Button>

                            <Button
                              className="mt-8 ml-2"
                              theme="danger"
                              onClick={() => this.toggle(items._id)}
                            >
                              <i class="material-icons">remove_circle</i>
                            </Button>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
              <this.Delete />
              <this.Edit />
            </CardBody>
          </Card>
        </Col>
      );
    }
  };
  handleRadio2 = (e, fruit) => {
    const newState = {};
    newState[fruit] = !this.state[fruit];
    this.setState({ ...this.state, ...newState });
  };

  // handleRadio = event => {
  //   const isClosed = event.currentTarget.value === "true" ? true : false;
  //   console.log("handle", isClosed);
  //   this.setState({ isClosed });
  // };
  AddNote = () => {
    let dummynote = "Follow up done";
    const { isClosed } = this.state;
    console.log(isClosed);
    return (
      <Card small className="mb-4">
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <h4>Next Follow up details</h4>
                  <hr />
                  <Row form>
                    {/* Description */}
                    <Col md="12" className="form-group">
                      <label htmlFor="feDescription">Note</label>

                      <FormTextarea
                        rows="5"
                        name="note"
                        onChange={this.handleChange}
                        defaultValue="Follow Up Done"
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label htmlFor="fePassword">Next Follow Up Date</label>
                      {isClosed === true ? (
                        <FormInput
                          type="date"
                          onChange={this.handleChange}
                          disabled
                        />
                      ) : (
                        <FormInput
                          type="date"
                          name="followUpDateTs"
                          onChange={this.handleChange}
                        />
                      )}
                      {/* <FormInput
                        type="date"
                        name="followUpDateTs"
                        onChange={this.handleChange}
                        
                      /> */}
                    </Col>
                    {/* <Col md="6" className="form-group">
                      <label htmlFor="feEmail">Patient Follow Up</label>
                      <FormSelect name="isClosed" onChange={this.handleChange}>
                        <option value="none">
                          Select
                        </option>
                        <option value={true}>Complete</option>
                        <option value={false}>Not Complete</option>
                      </FormSelect>
                    </Col> */}
                    <Col md="4" className="form-group ml-4">
                      <label htmlFor="fePassword">Patient Follow Up</label>
                      <br />
                      <input
                        style={{
                          width: "18px",
                          height: "15px"
                        }}
                        type="checkbox"
                        name="isClosed"
                        value="true"
                        checked={this.state.isClosed}
                        onChange={e => this.handleRadio2(e, "isClosed")}
                      />
                      &nbsp; Discontinued
                      {/* <FormCheckbox
                        name="isClosed"
                        value="true"
                        checked={isClosed === true}
                        onChange={this.handleRadio}
                      >
                        Discontinued
                      </FormCheckbox> */}
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center">
                    <Col lg="0" className="mt-1">
                      <Button
                        onClick={this.PostPatient}
                        theme="accent"
                        size="md"
                      >
                        Add Follow Up
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    );
  };
  Alerts = () => {
    if (this.state.updated) {
      return (
        <>
          <Alert theme="success">
            <a className="alert-link px-5 py-4 ">{this.state.errorCode}</a>
          </Alert>
        </>
      );
    } else if (this.state.error) {
      return (
        <>
          <Alert theme="warning">
            <a className="alert-link px-5 py-4">{this.state.errorCode}</a>
          </Alert>
        </>
      );
    } else {
      return <></>;
    }
  };
  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/dashbord" />;
    }
    return (
      <Container fluid className="main-content-container px-5 py-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Follow Up" className="text-sm-left mb-3" />
        </Row>
        <Row>
          <this.List />
        </Row>
        <this.AddNote />
        <this.Alerts />
      </Container>
    );
  }
}
export default Followup;
