import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormTextarea,
  Button,
  Container,
  FormSelect
} from "shards-react";
import Cookie from "js-cookie";
import PageTitle from "../components/common/PageTitle";

const axios = require("axios").default;
const URL = require("../ApiUrl");

class ClosedFollowUp extends Component {
  state = {
    page: 1,
    limit: 20,
    originalData: [],
    patientData: {}
  };
  getData = async (l, p) => {
    // console.log(l, p);
    let page = this.state.page;
    let limit = this.state.limit;

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/patient/list?page=1&limit=20&isClosed=true`,

      headers: { Authorization: `Bearer ${token}` }
    });

    //  console.log(response);
    if (response.data.statusCode === 200) {
      this.setState({
        patientData: response.data.data,
        originalData: response.data.data.items
      });
    }

    console.log(this.state.patientData);
  };
  componentDidMount() {
    this.getData();
  }
  List = () => {
    let patientList = [];
    if (this.state.patientData.items) {
      patientList = this.state.patientData.items;
    }
    return (
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0"></h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                    MRD Number
                  </th>

                  <th scope="col" className="border-0">
                    Phone
                  </th>
                  <th scope="col" className="border-0">
                   Follow Up Date
                  </th>
                  <th scope="col" className="border-0">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {patientList.map((items, key) => {
                  if (items.length > 0) {
                    return (
                      <tr>
                        <td>No items</td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td>{key + 1}</td>
                        <td>{items.name}</td>
                        <td>{items.MRDNo}</td>
                        <td>{items.mobile}</td>
                        <td>{new Date(items.followUpDateTs).toLocaleDateString()}</td>
                        <td>
                          <Link to={`closeddata/${items._id}`}>
                            <Button>
                              <i class="material-icons">edit</i> Edit
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
    );
  };

  render() {
    return (
      <Container fluid className="main-content-container px-5 py-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Closed Patient List" className="text-sm-left mb-3" />
        </Row>
        <Row>
          <this.List />
        </Row>
      </Container>
    );
  }
}
export default ClosedFollowUp;
