import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormTextarea,
  Button,
  Container,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import Cookie from "js-cookie";
import PageTitle from "../components/common/PageTitle";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
const axios = require("axios").default;
const URL = require("../ApiUrl");

class PatientList extends Component {
  state = {
    page: 1,
    limit: 30,
    originalData: [],
    patientData: {},
    hasNextPage: false
  };
  getData = async p => {
    // console.log(l, p);
    let page = p ? p : this.state.page;
    let limit = this.state.limit;
    console.log(page, "--------------");
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/patient/list?page=${page}&limit=${limit}`,

      headers: { Authorization: `Bearer ${token}` }
    });

    //  console.log(response);
    if (response.data.statusCode === 200) {
      this.setState({
        patientData: response.data.data,
        originalData: response.data.data
      });
    }

    console.log(this.state.patientData);
  };
  componentDidMount() {
    this.getData();
  }

  GetSearchPatient = async e => {
    let searchText = e.target.value;
    if (searchText === "") {
      this.getData();
    } else {
      const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
      let page = this.state.page;
      let limit = this.state.limit;
      let response = await axios({
        method: "get",
        url: `${URL.BASE_URL}/patient/list?MRDNo=${searchText}&page=${page}&limit=${limit}`,

        headers: { Authorization: `Bearer ${token}` }
      });

      console.log("🎉🎉🎉🎉🎉🎉SEARCHing");
      console.log(response);

      if (response && response.data !== null) {
        this.setState({
          patientData: response.data.data,
          page: 1,
          isLoaded: true
        });
      } else if (response && response.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: response.data.msg
        });
      } else {
        this.setState({
          status: false
        });
      }
    }
  };

  List = () => {
    let patientList = [];
    if (this.state.patientData.items) {
      patientList = this.state.patientData.items;
    }
    return (
      <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <InputGroup seamless className="mb-2 ">
              <InputGroupAddon size="lg">
                <Row>
                  <Col md="" className="">
                    <FormInput
                      placeholder="Search"
                      type="number"
                      onChange={this.GetSearchPatient}
                    />
                  </Col>
                  {/* <Col>
                    <Button className="mt-8 ml-2" theme="warning">
                      <i class="material-icons">visibility</i> Search
                    </Button>
                  </Col> */}
                </Row>
              </InputGroupAddon>
            </InputGroup>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                    MRD Number
                  </th>

                  <th scope="col" className="border-0">
                    Phone
                  </th>
                  <th scope="col" className="border-0">
                    Next Follow Up Date
                  </th>
                  <th scope="col" className="border-0">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {patientList.map((items, key) => {
                  if (items.length > 0) {
                    return (
                      <tr>
                        <td>No items</td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td>
                          {(this.state.page - 1) * this.state.limit + key + 1}
                        </td>
                        <td>{items.name}</td>
                        <td>{items.MRDNo}</td>
                        <td>{items.mobile}</td>
                        <td>
                          {new Date(items.followUpDateTs).toLocaleDateString()}
                        </td>
                        <td>
                          <Link to={`patientdata/${items._id}`}>
                            <Button>
                              <i class="material-icons">edit</i> Edit
                            </Button>
                          </Link>
                          <Link to={`followupdata/${items._id}`}>
                            <Button className="mt-8 ml-2" theme="warning">
                              <i class="material-icons">visibility</i> Follow up
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </CardBody>
          <this.Pagination2 />
        </Card>
      </Col>
    );
  };
  Pagination = () => {
    let totalPages = this.state.patientData
      ? this.state.patientData.totalPages
      : 1;
    let currentPage = this.state.page;
    let pageItems = Array.apply(null, Array(totalPages)).map(function(x, i) {
      return i;
    });
    return (
      <Col className="border-top py-2">
        <nav aria-label="Page navigation example">
          <Pagination
            className="pagination justify-content-end"
            listClassName="justify-content-end"
          >
            {pageItems.map((item, key) => {
              if (currentPage === key + 1) {
                return (
                  <PaginationItem className="active">
                    <PaginationLink
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      {key + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              } else {
                return (
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ page: key + 1 });
                        this.getData(key + 1);
                      }}
                    >
                      {key + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              }
            })}
          </Pagination>
        </nav>
      </Col>
    );
  };

  onClickLastPage = async () => {
    let currentPage = this.state.page;
    let lastPage = this.state.patientData.totalPages;
    await this.setState({ page: lastPage });
    this.getData(currentPage === lastPage);
  };

  onClickFirstPage = async () => {
    let currentPage = this.state.page;
    await this.setState({ page: 1 });
    this.getData(currentPage === 1);
  };

  Pagination2 = () => {
    let totalPages = this.state.patientData
      ? this.state.patientData.totalPages
      : 1;

    // let totalPages = this.state.patientData.totalPages;
    let currentPage = this.state.page;
    let lastPage = this.state.patientData.totalPages;
    // console.log(lastPage, "🤩🤩🤩🤩🤩");
    let pageItems = Array.apply(null, Array(totalPages)).map(function(x, i) {
      return i + 1;
    });
    // console.log(pageItems,"🎈🎈🎈🎈🎈🎈")
    let setPage = pageItems.slice(this.state.page -2, this.state.page + 2)
    if(setPage.length === 0){
      console.log("here")
      setPage = pageItems.slice(0,3)
    }
    // console.log(setPage,"🎁🎁🎁🎁")
    // console.log(pageItems.slice(this.state.page -2,this.state.page + 3),"💕💕💕💕💕")
    return (
      <Col className="border-top py-2">
        <nav aria-label="Page navigation example">
          <Pagination
            className="pagination justify-content-end"
            listClassName="justify-content-end"
          >
            <PaginationItem>
              <PaginationLink first onClick={this.onClickFirstPage} />
            </PaginationItem>
            <PaginationItem>
              {currentPage === 1 ? (
                <PaginationLink previous disabled />
              ) : (
                <PaginationLink
                  previous
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ page: currentPage - 1 });
                    this.getData(currentPage - 1);
                  }}
                />
              )}
            </PaginationItem>

            
            {setPage.map((item, key) => {
              if (currentPage === item) {
                return (
                  <>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        {/* {console.log(item + 1,"/////////")} */}
                        {/* {key + 1}  */}
                        {item}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                );
              } else {
                return (
                  <>
                    <PaginationItem >
                      <PaginationLink
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ page: item });
                          this.getData(item);
                        }}
                      >
                        {/* {key + 1} */}
                         {item}
                      </PaginationLink>
                    </PaginationItem>
                  </>
                );
              }
            })}
            <PaginationItem>
              {currentPage === lastPage ? (
                <>
                  <PaginationLink next disabled />
                </>
              ) : (
                <PaginationLink
                  next
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ page: currentPage + 1 });
                    this.getData(currentPage + 1);
                  }}
                />
              )}
            </PaginationItem>
            <PaginationItem>
              <PaginationLink last onClick={this.onClickLastPage} />
            </PaginationItem>
          </Pagination>
        </nav>
      </Col>
    );
  };

  render() {
    return (
      <Container fluid className="main-content-container px-5 py-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Patient List" className="text-sm-left mb-3" />
        </Row>
        <Row>
          <this.List />
        </Row>
      </Container>
    );
  }
}
export default PatientList;
