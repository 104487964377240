import React, { Component } from "react";

import PropTypes from "prop-types";
import { Container, Row, Col,Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import Tables from "./Tables";
import DashboardTables from "./DashboardTables";

import Cookie from "js-cookie";
// import PageTitle from "../components/common/PageTitle";

const axios = require("axios").default;
const URL = require("../ApiUrl");
class Dashboard extends Component {
  state = {
    totalPatients : 0,
    currentMonthTotalFollowUpPatients : 0
  };
  getData = async (l, p) => {
    // console.log(l, p);
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/patient/dashboard`,

      headers: { Authorization: `Bearer ${token}` }
    });

    //  console.log(response);
    if (response.data.statusCode === 200) {
      this.setState({
        totalPatients: response.data.data.totalPatients,
        currentMonthTotalFollowUpPatients: response.data.data.currentMonthTotalFollowUpPatients
      });
    }

    console.log(this.state.patientData);
  };
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Dashboard" className="text-sm-left mb-3" />
      </Row>
  
      {/* Small Stats Blocks */}
      <Row>
      <Col className="col-lg mb-4" key={"abcd2"} md={'6'}  sm = {"6"}>
          <SmallStats
            id={`small-stats-${2}`}
            variation="1"
            chartData={[{        
              label :  "Today",
              fill : "start",
              borderWidth : 1.5,
              backgroundColor : "rgba(0, 184, 216, 0.1)",
              borderColor : "rgb(0, 184, 216)",
              data : [1, 2, 1, 3, 5, 4, 7]
            }]}
            label={"Total Patients"}
            value={this.state.totalPatients}
          />
        </Col>
        <Col className="col-lg mb-4" key={"abcd2"} md={'6'}  sm = {"6"}>
          <SmallStats
            id={`small-stats-${2}`}
            variation="1"
            chartData={[{        
              label :  "",
              fill : "start",
              borderWidth : 1.5,
              backgroundColor : "rgba(0, 184, 216, 0.1)",
              borderColor : "rgb(0, 184, 216)",
              data : [1, 2, 1, 3, 5, 4, 7]
            }]}
            label={"This month patients"}
            value={this.state.currentMonthTotalFollowUpPatients}
          />
        </Col>
        
      </Row>
      <DashboardTables/>
    </Container>
    );
  }
}





// const BlogOverview = ({ smallStats }) => (
//   <Container fluid className="main-content-container px-4">
//     {/* Page Header */}
//     <Row noGutters className="page-header py-4">
//       <PageTitle title="Dashboard" className="text-sm-left mb-3" />
//     </Row>

//     {/* Small Stats Blocks */}
//     <Row>
//     <Col className="col-lg mb-4" key={"abcd2"} md={'6'}  sm = {"6"}>
//           <SmallStats
//             id={`small-stats-${2}`}
//             variation="1"
//             chartData={        
//               label =  "Today",
//               fill = "start",
//               borderWidth = 1.5,
//               backgroundColor = "rgba(0, 184, 216, 0.1)",
//               borderColor = "rgb(0, 184, 216)",
//               data = [1, 2, 1, 3, 5, 4, 7]
//             }
//             label={"Today"}
//             value={"2,390"}
//           />
//         </Col>
//         <Col className="col-lg mb-4" key={"abcd2"} md={'6'}  sm = {"6"}>
//           <SmallStats
//             id={`small-stats-${2}`}
//             variation="1"
//             chartData={        
//               label =  "Today",
//               fill = "start",
//               borderWidth = 1.5,
//               backgroundColor = "rgba(0, 184, 216, 0.1)",
//               borderColor = "rgb(0, 184, 216)",
//               data = [1, 2, 1, 3, 5, 4, 7]
//             }
//             label={"Today"}
//             value={"2,390"}
//           />
//         </Col>
        
//     </Row>
//     <Tables/>
//   </Container>
// );


export default Dashboard;
