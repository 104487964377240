import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, LoginLayout } from "./layouts";

// Route Views
import Dashbord from "./views/Dashbord";
import UserProfileLite from "./views/UserProfileLite";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import AddUser from "./views/AddUser";
import ListUser from "./views/listUsers";
import UserData from "./views/UserData";
import AddPatient from "./views/AddPatient";
import PatientList from "./views/PatientList";
import Followup from "./views/Followup";
import ClosedFollowUp from "./views/ClosedFollowUp";
import ClosedFollowUpDetails from "./views/ClosedFollowUpDetails";
import AddInvigilator from "./views/AddInvigilator";
import InvigilatorList from "./views/InvigilatorList";
import Logout from "./views/Logout";
import Report from "./views/Report";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashbord" />
  },
  {
    path: "/dashbord",
    layout: DefaultLayout,
    component: Dashbord
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
 
  
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  
  {
    path: "/add-user",
    layout: DefaultLayout,
    component: AddUser
  },
  {
    path: "/add-patient",
    layout: DefaultLayout,
    component: AddPatient
  },
  {
    path: "/patient-list",
    layout: DefaultLayout,
    component: PatientList
  },
  {
    path: "/patientdata/:id",
    layout: DefaultLayout,
    component: AddPatient
  },
  {
    path: "/closeddata/:id",
    layout: DefaultLayout,
    component: ClosedFollowUpDetails
  },
  {
    path: "/followupdata/:id",
    layout: DefaultLayout,
    component: Followup
  },
  {
    path: "/list-user",
    layout: DefaultLayout,
    component: ListUser
  },
  {
    path: "/userdata/:id",
    layout: DefaultLayout,
    component: UserData
  },
  {
    path: "/closedfollowup",
    layout: DefaultLayout,
    component: ClosedFollowUp
  },
  
  {
    path: "/addinvigilator",
    layout: DefaultLayout,
    component: AddInvigilator
  },
  {
    path: "/invigilator/:id",
    layout: DefaultLayout,
    component: AddInvigilator
  },
  {
    path: "/invigilator-list",
    layout: DefaultLayout,
    component: InvigilatorList
  },
 
  {
    path: "/report",
    layout: DefaultLayout,
    component: Report
  },

  {
    path: "/logout",
    layout: LoginLayout,
    component: Logout
  },

  {
    path: "/login",
    layout: LoginLayout,
    component: () => <Redirect to="/dashbord" />
  }
];
