export default function() {
  return [
    {
      title: "Dashboard",
      to: "/dashbord",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    
    
    {
      title: "Add Patient ",
      htmlBefore: '<i class="material-icons">person_add</i>',
      to: "/add-patient"
    },
    // {
    //   title: "Add User ",
    //   htmlBefore: '<i class="material-icons">add_circle_outline</i>',
    //   to: "/add-user"
    // },
    {
      title: "Patient List ",
      htmlBefore: '<i class="material-icons">groups</i>',
      to: "/patient-list"
    },
    {
      title: "Closed Follow Up",
      htmlBefore: '<i class="material-icons">person_off</i>',
      to: "/closedfollowup"
    },
    {
      title: "Add User",
      htmlBefore: '<i class="material-icons">admin_panel_settings</i>',
      to: "/addinvigilator"
    },
    {
      title: "User List",
      htmlBefore: '<i class="material-icons">supervisor_account</i>',
      to: "/invigilator-list"
    },
    {
      title: "Report ",
      htmlBefore: '<i class="material-icons">description</i>',
      to: "/report"
    },
    {
      title: "Logout ",
      htmlBefore: '<i class="material-icons">&#xE879;</i>',
      to: "/logout"
    }
    /*
    {
      title: "Forms & Components",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/components-overview"
    },
    {
      title: "Tables",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/tables"
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite"
    },
    {
      title: "Errors",
      htmlBefore: '<i class="material-icons">error</i>',
      to: "/errors"
    }
    */
  ];
}
